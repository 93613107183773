import React from 'react';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa';
// eslint-disable-next-line import/no-deprecated
import {classes} from './OptionsDropdown.st.css';
import s from './OptionsDropdown.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {UserInputType} from '../../../constants';
import {ErrorTooltipProvider} from '../../ErrorTooltipProvider/ErrorTooltipProvider';

export interface OptionsDropdownProps {
  optionIndex: number;
}

export enum DataHook {
  OptionsDropdown = 'options-dropdown',
  OptionsDropdownTitle = 'options-dropdown-title',
}

@withGlobalProps
@withTranslations('globals.texts')
export class OptionsDropdown extends React.Component<
  OptionsDropdownProps & ProvidedGlobalProps & IProvidedTranslationProps
> {
  private readonly handleChange = ({id: selectionId}: DropdownOptionProps) => {
    const {
      optionIndex,
      globals: {handleUserInput, validate},
    } = this.props;

    const nextSelection = this.productOption.selections.find(({id}) => id.toString() === selectionId);

    handleUserInput(UserInputType.Selection, nextSelection, optionIndex);

    validate();
  };

  private get activeSelection() {
    const {
      optionIndex,
      globals: {userInputs},
    } = this.props;

    return userInputs[UserInputType.Selection][optionIndex];
  }

  private get productOption() {
    const {
      optionIndex,
      globals: {product},
    } = this.props;

    return product.options[optionIndex];
  }

  private get dropdownOptions(): DropdownOptionProps[] {
    const {
      globals: {variantInfo, isBackInStockEnabled},
    } = this.props;

    const getSelectionAvailabilityInfo = (selectionId: number) =>
      variantInfo.selectionsAvailability[this.productOption.id][selectionId];

    return this.productOption.selections
      .filter((selection) => getSelectionAvailabilityInfo(selection.id).isVisible)
      .map((selection) => ({
        id: selection.id.toString(),
        isSelectable: isBackInStockEnabled ? true : getSelectionAvailabilityInfo(selection.id).isSelectable,
        value: selection.description,
        unavailable: isBackInStockEnabled ? !getSelectionAvailabilityInfo(selection.id).isSelectable : false,
      }));
  }

  public render(): JSX.Element {
    const {
      t,
      optionIndex,
      globals: {errorPlacement, isProductSubmitted, userInputErrors, experiments},
    } = this.props;

    const {title} = this.productOption;

    return (
      <div>
        {!experiments.useDropdownTpaLabel && (
          <div data-hook={DataHook.OptionsDropdownTitle} className={s.title}>
            <label>{title}</label>
          </div>
        )}
        <ErrorTooltipProvider
          className={s.dropDownErrorTooltip}
          content={t('SELECT_OPTION_WARNING', {optionTitle: title})}
          placement={errorPlacement}
          show={isProductSubmitted && userInputErrors[UserInputType.Selection][optionIndex]}>
          <Dropdown
            className={classes.root}
            options={this.dropdownOptions}
            onChange={this.handleChange}
            placeholder={t('SELECT_PLACE_HOLDER')}
            initialSelectedId={(this.activeSelection?.id || '').toString()}
            data-hook={DataHook.OptionsDropdown}
            label={experiments.useDropdownTpaLabel ? title : ''}
            mobileNativeSelect
          />
        </ErrorTooltipProvider>
      </div>
    );
  }
}
